import React, { useState } from 'react'
import { Container } from "../utils/Container"
import { queryServer } from '../utils/queryServer'
import { RequestResponseTypes } from '../utils/Common/RequestResponseTypes'

export const UserScanTrackerPage = () => {
    const [userEmail, setUserEmail] = useState('')
    const [userData, setUserData] = useState<RequestResponseTypes['getUserByEmail']['response']['data']>()

    const renderUserData = () => {
        if (userData == null) {
            return null
        } else if (!userData.success) {
            return <p className='mg-t-20 mg-b-20'>User with email <b>{userData.email}</b> doesn't exist</p>
        } else {
            return <div style={{
                border: "1px solid lightgray",
                marginTop: "20px",
                marginBottom: "20px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingRight: "5px",
                paddingLeft: "5px",
                color: "black",
                fontSize: "110%",
                borderRadius: "5px",
            }}>
                <div>{userData.firstname} {userData.lastname}</div>
                <div>User ID: {userData.userId}</div>
                <div>Email: {userData.email}</div>
            </div>
        }
    }

    return <Container
        title='optimizations'
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: true,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        <div className='contributors-exercise-page'>
            <input
                type="text"
                placeholder="Enter user's email address..."
                onChange={e => setUserEmail(e.target.value)}
                value={userEmail}
                className='mg-b-5'
                style={{ fontSize: '16px', width: '100%' }}
            />
            <span
                className='span-btn'
                style={{ margin: 0 }}
                onClick={async () => {
                    if (userEmail === '') {
                        return
                    }

                    setUserData(undefined)

                    const data = await queryServer(
                        'getUserByEmail',
                        { email: userEmail },
                    )

                    setUserData(data.data)
                }}
            >
                Find user
            </span>

            {renderUserData()}
        </div>
    </Container>
}
