import React from 'react'
import { Container } from "../utils/Container"
import { SCAN_PRICING_PLANS } from '../utils/Common/Common'
import { AppContext } from '../contexts/AppContext'

export const PricingPage = () => {
    const { gCountryId } = React.useContext(AppContext)

    return <Container
        title={"Pricing"}
        withFooter={{
            withFooter: true,
            setFooterToBootom: true,
        }}
        whiteWrapper={{
            whiteWrapper: false,
            expandWhiteWrapperToWholeScreen: true,
        }}
    >
        <div className="row row-sm ">
            {SCAN_PRICING_PLANS[gCountryId].map(el => {
                return <div className="col-lg-4 d-flex mb-4" key={el.orderNumber}>
                    <div className="card card-pricing-two flex-fill card-equal justify-content-center">
                        <div className="bg-transparent card-header pt-4 border-0">
                            <h3 className='pricing-price' style={{ fontSize: '38px' }}>
                                {el.amountDescription}
                            </h3>
                        </div>
                        <ul className="pricing-list" style={{
                            fontSize: '16px',
                            color: '#3d3d3d',
                        }}>
                            <li>{el.nScansIncludedDescription}</li>
                            <li>{el.lifetimeDescription}</li>
                        </ul>
                        <button className="btn btn-primary btn-pricing">
                            Kupi
                        </button>
                    </div>
                </div>
            })}
        </div>
    </Container>
}
