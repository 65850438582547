import React from 'react'
import { AppContext } from './contexts/AppContext'
import { Route, Routes } from 'react-router-dom'
import { LoggedIn } from './LoggedIn'
import { useCheckLoginStatus } from './utils/useCheckLoginStatus'
import { ENVIRONMENT_CALCULATED } from './utils/constants'
import { NotLoggedIn } from './NotLoggedIn'
import { Loader } from './utils/Loader'
require('firebase/auth')

const client__version__ = '9.0.5'

export const App = () => {
  React.useEffect(() => {
    console.log('Started client. Env:', ENVIRONMENT_CALCULATED, ` client__version__: ${client__version__}`)
  }, [])

  const { gLoggedIn } = React.useContext(AppContext)

  useCheckLoginStatus()

  let content
  if (gLoggedIn == null) {
    content = <Loader
      className='mg-30'
      style={{ width: '3rem', height: '3rem' }}
      color="primary"
    />
  } else {
    content = <Routes>
      {!gLoggedIn && <Route path='*' element={<NotLoggedIn />} />}
      {gLoggedIn && <Route path='*' element={<LoggedIn />} />}
    </Routes>
  }

  return content
}
